import React from 'react';
import PropTypes from 'prop-types';

import {
  Link,
  Grid,
  Typography,
  withStyles,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import color from 'styles/color';

import Layout, { LayoutTitle } from 'components/Layout';
import SeoHelmet from 'components/SeoHelmet';

const styles = ({ spacing, typography, palette }) => ({
  gmapCanvasId: {
    position: 'relative',
    textAlign: 'right',
    height: 320,
    width: '100%',
  },
  gmapCanvasClass: {
    overflow: 'hidden',
    background: 'none !important',
    height: 320,
    width: '100%',
  },
  label: {
    fontWeight: 'bold',
    fontSize: typography.pxToRem(12),
    marginRight: spacing(1),
    marginBottom: spacing(1),
  },
  infoWrap: {
    marginTop: spacing(4),
  },
  addressWrap: {
    marginBottom: spacing(1),
  },
  workDays: {
    backgroundColor: color.fade(palette.grey[100], 0.5),
    borderRadius: typography.pxToRem(6),
  },
});

const Contact = ({ classes }) => (
  <Layout>
    <SeoHelmet title="Contact" />
    <LayoutTitle title="Informații de contact" />
    <Typography color="textPrimary" gutterBottom component="p">
      Contactați-ne si ne va face placere sa va ajutam
    </Typography>
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <div className={classes.infoWrap}>
          <Typography component="div" className={classes.addressWrap}>
            <Typography className={classes.label}>
              Companie Comercială:
            </Typography>
            IRRIGATION & LANDFLOWER S.R.L.
          </Typography>
          <Typography component="div" className={classes.addressWrap}>
            <Typography className={classes.label}>Adresa:</Typography>
            Str: Zizinului, nr:110, Oraș: Brasov, Jud: Brasov, Cod postal:
            507220
          </Typography>
          <Typography component="div" className={classes.addressWrap}>
            <Typography className={classes.label}>Email:</Typography>
            <Link
              href="mailto:irilandbv@gmail.com"
              role="link"
              tabIndex="0"
              target="_blank">
              irilandbv@gmail.com
            </Link>
          </Typography>
          <Typography component="div" className={classes.addressWrap}>
            <Typography className={classes.label}>Telefon:</Typography>
            0751500127
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography component="div" className={classes.addressWrap}>
          <Typography className={classes.label}>Program:</Typography>
          <List dense className={classes.workDays}>
            <ListItem>
              <ListItemText primary="Luni: 9.00 – 19.00" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Marti: 9.00 – 19.00" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Miercuri: 9.00 – 19.00" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Joi: 9.00 – 19.00" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Vineri: 9.00 – 19.00" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Sambata: Inchis" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Duminica: Inchis" />
            </ListItem>
          </List>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item xs={12}>
        <div className="mapouter">
          <div className={classes.gmapCanvasClass}>
            <iframe
              title="irriland.ro"
              width="100%"
              height="320"
              id={classes.gmapCanvasId}
              src="https://maps.google.com/maps?q=str:%20Zizinului,%20nr:110,%20Ora%C8%99:%20Brasov,%20Jud:%20Brasov,%20Cod%20postal:%20507220&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            />
          </div>
        </div>
      </Grid>
    </Grid>
  </Layout>
);
Contact.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Contact);
